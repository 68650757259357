import CheckboxInput from 'core/CheckboxInput';
import { useFormContext } from 'react-hook-form';
import ErrorInput from 'core/ErrorInput';
import cn from './style.module.sass';

type CheckboxesBlockProps = {
    defaultCheckedType?: string;
    showCheckboxes: boolean;
    isEnterprise: boolean;
};

export default function CheckboxesBlock({
    defaultCheckedType,
    showCheckboxes = true,
    isEnterprise,
}: CheckboxesBlockProps) {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    if (!showCheckboxes) {
        return null;
    }

    return (
        <div className={cn.checkboxBlock}>
            <h2 className={cn['checkboxBlock__title']}>
                {isEnterprise ? 'Какой продукт вас интересует?' : 'Что хотите обсудить?'}
            </h2>
            <div className={cn['checkboxBlock__list']}>
                {isEnterprise ? (
                    <>
                        <CheckboxInput
                            title="1С-Битрикс24: Enterprise"
                            defaultChecked={defaultCheckedType === 'Enterprise'}
                            {...register('userchoice')}
                        />
                        <CheckboxInput
                            title="1С-Битрикс: Enterprise. Управление сайтом"
                            defaultChecked={defaultCheckedType === 'Управление сайтом'}
                            {...register('userchoice')}
                        />
                    </>
                ) : (
                    <>
                        <CheckboxInput
                            title="Маркетинг"
                            defaultChecked={defaultCheckedType === 'Маркетинг'}
                            {...register('userchoice')}
                        />
                        <CheckboxInput
                            title="Разработка"
                            defaultChecked={defaultCheckedType === 'Разработка'}
                            {...register('userchoice')}
                        />
                        <CheckboxInput
                            title="Аутстаф"
                            defaultChecked={defaultCheckedType === 'Аутстаф'}
                            {...register('userchoice')}
                        />
                    </>
                )}
                <ErrorInput message={errors?.userchoice?.message as string} />
            </div>
        </div>
    );
}
