'use client';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import BasicButton from 'core/BasicButton';
import BasicImage from 'core/BasicImage';
import { getExternalLink } from 'core/BasicImage/basic-image.util';
import BasicLink from 'core/BasicLink';
import { ChangeEvent, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import CheckboxesBlock from 'shared/sections/OfferBody/OfferForm/CheckboxesBlock';
import TextField from 'shared/sections/OfferBody/OfferForm/TextField';
import { z } from 'zod';

import cn from './style.module.sass';

const SignUpSchema = z.object({
    name: z
        .string()
        .min(1, { message: 'слишком короткое имя' })
        .max(100, { message: 'слишком длинное имя' }),
    mail: z
        .string()
        .email({ message: 'введите корректный email' })
        .max(100, { message: 'слишком длинный адрес' }),
    phone: z.string(),
    words: z.string(),
    userchoice: z.array(z.string()),
});

type SignUpSchemaType = z.infer<typeof SignUpSchema>;

type OfferFormProps = {
    defaultCheckedType: string;
    showCheckboxes: boolean;
    isEnterprise: boolean;
    isSolution?: boolean;
};

export default function OfferForm({
    showCheckboxes,
    defaultCheckedType,
    isEnterprise,
    isSolution,
}: OfferFormProps) {
    const [fileUpload, setFileUpload] = useState<FileList | null>(null);
    const methods = useForm<SignUpSchemaType>({
        resolver: zodResolver(SignUpSchema),
        defaultValues: { name: '', mail: '', phone: '', words: '', userchoice: [] },
    });
    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { errors },
    } = methods;

    function getBase64(file: File | null | undefined): Promise<string> {
        if (!file) {
            return Promise.reject('нет файла');
        } else {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = error => reject(error);
            });
        }
    }

    const uploadFile = (event: ChangeEvent<HTMLInputElement>) => {
        setFileUpload(event?.target.files);
    };

    async function onSubmit(data: SignUpSchemaType) {
        try {
            let h1;
            if (document.getElementsByTagName('h1').length) {
                h1 = document.getElementsByTagName('h1')[0]?.innerText;
            } else {
                h1 = 'главная страница';
            }

            const file = fileUpload?.length ? fileUpload[0] : null;

            const file64 = getBase64(file)
                .then(data => {
                    const [, file64] = data.split('base64,');
                    return file64;
                })
                .catch(err => console.log(err));

            const totalData = {
                fields: {
                    TITLE: data.name,
                    OPENED: 'Y',
                    CATEGORY_ID: 2,
                    ASSIGNED_BY_ID: 1290,
                    PROBABILITY: 0,
                    CURRENCY_ID: 'RUB',
                    OPPORTUNITY: 0,
                    BEGINDATA: new Date(),
                    DATA_CREATE: new Date(),
                    UF_CRM_1590419608: {
                        fileData: fileUpload ? [fileUpload[0]?.name, file64] : null,
                    },
                    UF_CRM_58F5E45E004FF: document.cookie,
                    UF_CRM_1505909277: data.name,
                    UF_CRM_1505909288: data.phone,
                    UF_CRM_1505909324: data.mail,
                    UF_CRM_1674482952485: data.words,
                    UF_CRM_1590419530: data.userchoice.filter(el => el !== undefined).join(','),
                    UF_CRM_1505909447: h1,
                    UF_CRM_1505909470: window.location.href,
                },
            };
            await axios
                .post(
                    'https://cp.webpractik.ru/rest/1/58pkir8vmteeyte8/crm.deal.add.json',
                    totalData
                )
                .then(() => {
                    window?.ym(12895207, 'reachGoal', 'form');
                })
                .catch(e => {
                    console.log(e);
                    throw new Error('что-то пошло не так');
                });
            reset();
            setFileUpload(null);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={event => void handleSubmit(onSubmit)(event)}>
                <CheckboxesBlock
                    showCheckboxes={showCheckboxes}
                    defaultCheckedType={defaultCheckedType}
                    isEnterprise={isEnterprise}
                />
                <div className={cn['words-container']}>
                    <label htmlFor="words">
                        {/* eslint-disable-next-line sonarjs/no-all-duplicated-branches */}
                        {isSolution ? (
                            <div className={cn['words-container__small-title']}>
                                Пожалуйста, кратко опишите вашу задачу
                            </div>
                        ) : (
                            <div className={cn['words-container__small-title']}>
                                Несколько слов о вашей задаче
                            </div>
                        )}
                    </label>
                    <textarea {...register('words')} className={cn['words-container__textarea']} />
                    <label className={cn['words-container__add-file-label']}>
                        <BasicImage
                            width={16}
                            height={16}
                            src="/images/svg/attach.svg"
                            alt="add file"
                            loading="lazy"
                            className={cn['words-container__add-file-img']}
                        />
                        Прикрепить файл
                        <input
                            className={cn['words-container__add-file-input']}
                            name="addfile"
                            type="file"
                            onChange={event => uploadFile(event)}
                        />
                    </label>
                    <div>{fileUpload && fileUpload.length ? fileUpload[0]?.name : null}</div>
                </div>
                <div className={cn['contacts-container']}>
                    <div className={cn['contacts-container__small-title']}>Контактные данные</div>
                    <div className={cn['contacts-container__inputs']}>
                        <TextField
                            isError={errors.name}
                            title="Имя"
                            type="text"
                            {...register('name')}
                        />
                        <TextField
                            isError={errors.mail}
                            title="E-mail"
                            type="email"
                            {...register('mail')}
                        />
                        <div className={cn['contacts-container__label']}>
                            <span className={cn['contacts-container__input-title']}>Телефон</span>
                            <Controller
                                control={control}
                                name="phone"
                                render={({ field: { onChange, value } }) => (
                                    <InputMask
                                        mask="9 (999) 999-9999"
                                        type="tel"
                                        value={value}
                                        name="phone"
                                        className={cn['contacts-container__input']}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className={cn['submit-container']}>
                    <BasicButton className={cn['submit-container__submit-btn']} type="submit">
                        <span>отправить</span>
                    </BasicButton>
                    <div className={cn['submit-container__submit-text']}>
                        Нажимая на&nbsp;кнопку, вы&nbsp;даете{' '}
                        <BasicLink
                            href={getExternalLink('/files/PersonalData.pdf')}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            согласие на&nbsp;обработку персональных данных
                        </BasicLink>{' '}
                        и&nbsp;соглашаетесь с&nbsp;
                        <BasicLink
                            href={getExternalLink('/files/WebpractikPolicy.pdf')}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            политикой в отношении обработки персональных данных.
                        </BasicLink>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
}
